.container {
    width: 50%;
    padding: 10px 50px;
    position: relative;
}

.containerScroll {
    animation: movedown 1s linear forwards;
    opacity: 0;
}

@keyframes movedown {
    0% {
        opacity: 1;
        transform: translateY(-30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.containerScroll:nth-child(1){
    animation-delay: 0.5s;
}
.containerScroll:nth-child(2){
    animation-delay: 1s;
}
.containerScroll:nth-child(3){
    animation-delay: 1.5s;
}
.containerScroll:nth-child(4){
    animation-delay: 2s;
}

.text_box {
    padding: 20px 30px;
    background: #4d5061;
    color: #fff;
    position: relative;
    border-radius: 6px;
}

.left_box {
    left: 0;
}

.right_box {
    left: 50%;
}

.image {
    position: absolute;
    right: -1.5rem;
    top: 2.2rem;
    z-index: 10;
    border-radius: 50%;
    width: 3rem;
}

.right_image {
    left: -1.5rem;
}

.timelineScroll::after {
    content: "";
    position: absolute;
    width: 0.3rem;
    height: 100%;
    background-color: #4d5061;
    top: 0;
    left: 50%;
    margin-left: -0.2rem;
    z-index: -1;
    animation: moveline 3s linear forwards;
}

@keyframes moveline {
    0% {
        height: 0%;
    }
    100%{
        height: 100%;
    }
}

.left_arrow {
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 10;
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-left: 1rem solid #4d5061;
    right: -1rem;
}

.right_arrow {
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 10;
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-right: 1rem solid #4d5061;
    left: -1rem;
}

@media screen and (max-width: 600px) {
    .timeline {
        margin: 50px auto;
    }
    .timeline::after {
        left: 30px;
    }
    .container {
        width: 100%;
        padding-left: 80px;
        padding-right: 25px;
    }
    .text_box {
        font-size: 13px;
    }
    .right_box {
        left: 0;
    }
    .image {
        left: 0.5rem;
    }
    .right_arrow, .left_arrow {
        border-right: 1rem solid #4d5061;
        border-left: 0;
        left: -1rem;
    }
}